import {Link as DefaultLink, useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {Button as DefaultButton} from './Button';
import {MainPage} from './MainPage';

export const Home = () => {
  const [params] = useSearchParams();

  const clientId = params.get('clientId') ?? '';
  const merchantId = params.get('merchantId') ?? '';
  const managementToken = params.get('managementToken') ?? '';

  return (
    <MainPage>
      <Wrapper>
        <Title>No vehicle connected!</Title>
        <P>At the moment there are no vehicles connected.</P>
        <Link to={`register?clientId=${clientId}&managementToken=${managementToken}&merchantId=${merchantId}`}>
          <Button inverted={true} style={{marginTop: '3rem'}}>
            <Svg>
              <path
                // eslint-disable-next-line max-len
                d="M14.41,6.91H8.09V.59A.59.59,0,0,0,7.5,0a.6.6,0,0,0-.59.59h0V6.91H.59A.59.59,0,0,0,0,7.5a.6.6,0,0,0,.59.59H6.91v6.32A.59.59,0,0,0,7.5,15a.6.6,0,0,0,.59-.59V8.09h6.32A.59.59,0,0,0,15,7.5a.6.6,0,0,0-.59-.59Z"
              />
            </Svg>
            Register
          </Button>
        </Link>
      </Wrapper>
    </MainPage>
  );
};

const Wrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  color: #59af36;
  font-weight: 500;
`;

const P = styled.p`
  font-size: 1.125rem;
`;

const Svg = styled.svg.attrs({
  version: '1.1',
  height: '100%',
  viewBox: '0 0 15 15',
  width: '100%',
  xmlns: 'http://www.w3.org/2000/svg',
  xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
  position: absolute;
  left: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  fill: #59af36;
`;

const Button = styled(DefaultButton)`
  display: flex;
  align-items: center;
  position: relative;

  &:hover ${Svg} {
    fill: #ffffff;
  }
`;

const Link = styled(DefaultLink)`
  text-decoration: none;
  width: 100%;
  flex: 1;

  ${Button} {
    width: 100%;
  }
`;
