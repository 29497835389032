import * as React from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';
import {ODSAdapter} from '../adapter/ODSAdapter';
import {maxWidthHeight} from '../utility/constants';
import {CustomInput, CustomInputContainer, DateTimePicker, Label} from './components/DateTimePicker';
import {Spinner} from './Spinner';

enum ActionLoadingState {
  None,
  Loading,
  Error,
  Success,
}

const passcode = 'passcode';

const TestBench = () => {
  const [params] = useSearchParams();
  const serviceToken = params.get('serviceToken') ?? '';
  const managementToken = params.get('managementToken') ?? '';
  const [startDate, setStartDate] = React.useState(0);
  const [endDate, setEndDate] = React.useState(0);
  const [licensePlate, setLicensePlate] = React.useState('');

  const [orderState, setOrderState] = React.useState(ActionLoadingState.None);
  const [otpRequestState, setOtpRequestState] = React.useState(ActionLoadingState.None);
  const [requestId, setRequestId] = React.useState('');
  const [OTPLink, setOTPLink] = React.useState('');

  const placeOrder = async () => {
    const uuid = uuidv4();
    setOrderState(ActionLoadingState.Loading);
    const timestampStart = startDate;
    const timestampEnd = endDate;
    try {
      const successfullRequestId = await ODSAdapter.initiateODS(
        managementToken,
        serviceToken,
        licensePlate,
        uuid,
        [timestampStart, timestampEnd],
        passcode,
      );
      // save sent data in a object
      const data = {
        uuid: successfullRequestId,
        licensePlate,
        start: timestampStart,
        end: timestampEnd,
        time: new Date().toLocaleString(),
        passcode,
      };

      // save current date in readable format in local storage
      localStorage.setItem(uuid, JSON.stringify(data));
      // eslint-disable-next-line no-console
      console.log(successfullRequestId, timestampStart, timestampEnd);
      setRequestId(successfullRequestId);
      setOrderState(ActionLoadingState.Success);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOrderState(ActionLoadingState.None);
    } catch (error) {
      setOrderState(ActionLoadingState.Error);
      console.error(JSON.stringify(error));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOrderState(ActionLoadingState.None);
    }
  };

  const requestOTP = async () => {
    setOtpRequestState(ActionLoadingState.Loading);
    try {
      const timestamp = startDate;
      // 8h from start date is the maximum time for OTP request
      const timestampEnd = timestamp + 1000 * 60 * 60 * 8;
      const otpLink = await ODSAdapter.getODSOTP(managementToken, serviceToken, requestId, [
        timestamp,
        endDate < timestampEnd ? endDate : timestampEnd,
      ]);
      setOTPLink(otpLink);
      setOtpRequestState(ActionLoadingState.Success);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOtpRequestState(ActionLoadingState.None);
    } catch (error) {
      setOtpRequestState(ActionLoadingState.Error);
      console.error(JSON.stringify(error));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOtpRequestState(ActionLoadingState.None);
    }
  };

  const reset = () => {
    setStartDate(0);
    setEndDate(0);
    setLicensePlate('');
    setOrderState(ActionLoadingState.None);
    setOTPLink('');
    setRequestId('');
  };

  return (
    <Page>
      <h1>TestBench</h1>
      <CustomButton
        onClick={() => {
          window.open(
            // eslint-disable-next-line max-len
            'https://ods.2hire.io/webapp?client_id=rDg5XtKJepiaACd7V2Yr7ZNJ&merchant_id=bf70c17f-3480-4434-bacc-aa7b0c4b2738',
            '_blank',
          );
        }}
      >
        Opt in
      </CustomButton>
      <DateTimePicker label="Start Date" value={startDate} onChange={(n) => setStartDate(n)} />
      <DateTimePickerTopMargin label="End Date" minDate={startDate} value={endDate} onChange={(n) => setEndDate(n)} />
      <GenericCustomInputTopMargin
        label="License plate"
        value={licensePlate}
        onChange={(e) => setLicensePlate(e.target.value)}
      />
      <CustomButtonTopMargin
        disabled={orderState !== ActionLoadingState.None || licensePlate === '' || startDate === 0 || endDate === 0}
        onClick={() => {
          placeOrder();
        }}
      >
        {orderState === ActionLoadingState.None && 'Create delivery'}
        {orderState === ActionLoadingState.Loading && <Spinner />}
        {orderState === ActionLoadingState.Success && 'Delivery created'}
        {orderState === ActionLoadingState.Error && 'Error'}
      </CustomButtonTopMargin>
      <CustomButtonTopMargin
        disabled={
          orderState !== ActionLoadingState.None ||
          licensePlate === '' ||
          startDate === 0 ||
          endDate === 0 ||
          requestId === ''
        }
        onClick={() => {
          requestOTP();
        }}
      >
        {otpRequestState === ActionLoadingState.None && 'Request OTP'}
        {otpRequestState === ActionLoadingState.Loading && <Spinner />}
        {otpRequestState === ActionLoadingState.Success && 'OTP requested'}
        {otpRequestState === ActionLoadingState.Error && 'Error'}
      </CustomButtonTopMargin>
      {OTPLink !== '' && (
        <CustomButtonTopMargin
          onClick={() => {
            window.open(OTPLink, '_blank');
          }}
        >
          Open delivery app
        </CustomButtonTopMargin>
      )}
      <CustomButtonTopMargin onClick={() => reset()}>Reset</CustomButtonTopMargin>
    </Page>
  );
};

const Page = styled.div`
  ${maxWidthHeight}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GenericCustomInput = ({
  label,
  className,
  value,
  type,
  onChange: handleOnChange,
}: {
  label: string;
  className?: string;
  value?: string;
  type?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}) => {
  return (
    <CustomInputContainer className={className}>
      <Label>{label}</Label>
      <CustomInput type={type} value={value} onChange={handleOnChange} />
    </CustomInputContainer>
  );
};

const GenericCustomInputTopMargin = styled(GenericCustomInput)`
  margin-top: 1rem;
`;

const DateTimePickerTopMargin = styled(DateTimePicker)`
  margin-top: 1rem;
`;

const CustomButton = styled.button`
  background-color: #111827;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.75rem 1.2rem;
  text-align: center;
  text-decoration: none #6b7280 solid;
  text-decoration-thickness: auto;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  min-width: 12rem;
  min-height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #374151;
  }
  &:focus {
    box-shadow: none;
    outline: 2px solid white;
    outline-offset: 2px;
  }
  &:disabled {
    background-color: #05060f;
    color: #ffffff99;
    cursor: not-allowed;
  }
`;

const CustomButtonTopMargin = styled(CustomButton)`
  margin-top: 1rem;
`;

export default TestBench;
