import * as React from 'react';
import styled from 'styled-components';
import {safariSelector} from '../../utility/constants';

export const DateTimePicker = ({
  label,
  className,
  value,
  minDate,
  onChange: handleOnChange,
}: {
  label?: string;
  className?: string;
  value?: number;
  minDate?: number;
  onChange: (n: number) => void;
}) => {
  return (
    <CustomInputContainer className={className}>
      {label && <Label>{label}</Label>}
      <CustomInput
        min={minDate !== undefined ? convertUnixTimestampToDateTime(minDate || undefined) : undefined}
        type={'datetime-local'}
        value={convertUnixTimestampToDateTime(value || undefined)}
        onChange={(e) => {
          let number = convertDateTimeToUnixTimestamp(e.target.value);
          handleOnChange(isNaN(number) ? 0 : number);
        }}
      />
    </CustomInputContainer>
  );
};

export const CustomInput = styled.input`
  font-size: 1.5rem;
  height: 44px;
  max-width: 95vw;
  ${safariSelector`
    max-width: 95dvw;
  `}
  box-sizing: border-box;
  background-color: #05060f0a;
  border-radius: 0.5rem;
  padding: 0 1rem;
  border: 2px solid transparent;
  transition: border-color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s, color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s,
    background 0.2s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
  &:hover,
  &:focus {
    outline: none;
    border-color: #05060f;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.3rem;
  font-size: 0.9rem;
  font-weight: bold;
  color: #05060f99;
  transition: color 0.3s cubic-bezier(0.25, 0.01, 0.25, 1) 0s;
`;

export const CustomInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:hover label,
  &:focus input {
    color: #05060fc2;
  }
`;

const convertUnixTimestampToDateTime = (unixTimestamp?: number) => {
  if (unixTimestamp === undefined) {
    return '';
  }
  const date = new Date(unixTimestamp);
  // normalise numbers to two digits
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString().padStart(4, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const convertDateTimeToUnixTimestamp = (dateTime: string) => {
  const date = new Date(dateTime);
  return date.getTime();
};
