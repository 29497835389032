import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {Button} from './Button';
import {OptInLink} from './constants';
import {MainPage} from './MainPage';

export const Register = () => {
  const [params] = useSearchParams();

  const clientId = params.get('clientId') ?? '';
  const merchantId = params.get('merchantId') ?? '';
  const managementToken = params.get('managementToken') ?? '';

  return (
    <MainPage>
      <Wrapper>
        <Title>
          Register your
          <br />
          connected car!
        </Title>
        <P>
          By registering your vehicle as a connected one, you will access a contactless charging service from E-Gap.
        </P>
        <P>
          E-Gap&#39;s operator will use the GPS of your connected car to locate its position. The operator will then
          digitally unlock the vehicle and charge it. No keys are needed to access the charging socket!
        </P>
        <P>
          At the end of the service, the car will be locked by the operator. You will receive a notification on your
          smartphone confirming the delivery of the service.
        </P>
        <Button
          as="a"
          href={OptInLink(clientId, merchantId, managementToken)}
          rel="noreferer"
          style={{marginTop: '1rem'}}
          target="_blank"
        >
          Register
        </Button>
      </Wrapper>
    </MainPage>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  color: #59af36;
  font-weight: 500;
`;

const P = styled.p`
  font-size: 1.125rem;
`;
