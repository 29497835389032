import styled from 'styled-components';
import PosteItalianeLogo from '../assets/Poste_italiane_logo.svg';
import {maxWidthHeight} from '../utility/constants';

const Landing = () => {
  return (
    <Background>
      <Header>
        <Logo src={PosteItalianeLogo} />
      </Header>
      <Container>
        <LeftContainer>
          <YellowBox>
            <div>Scopri il servizio In car Delivery e partecipa</div>
            <div>gratuitamente alla sperimentazione!</div>
          </YellowBox>
          <YellowButton
            onClick={() => {
              window.open(
                // eslint-disable-next-line max-len
                'https://fidm.eu1.gigya.com/oidc/op/v1.0/4_2avWjdulD7QdNmKih71PEg/authorize?client_id=rDg5XtKJepiaACd7V2Yr7ZNJ&redirect_uri=https://ods.2hire.io/api/v1/oauth/fca/bf70c17f-3480-4434-bacc-aa7b0c4b2738&scope=openid%20email%20profile&response_type=code&nonce=99990',
                '_blank',
              );
            }}
          >
            Registrati
          </YellowButton>
        </LeftContainer>
      </Container>
    </Background>
  );
};

export default Landing;

// Create component with styled-components
// with background color 109	171	223	that fills the entire screen.
const Background = styled.div`
  background-color: #0085b2;
  ${maxWidthHeight}
  display: flex;
  flex-direction: column;
`;

// Create an header with height of 50px and dynamic width
// with background color 60	101	133
const Header = styled.div`
  height: 50px;
  width: 100%;
  background-color: #006699;
  padding-left: 2rem;
  padding-right: 2rem;
`;

// Poste logo with 100% height
const Logo = styled.img`
  height: 100%;
`;

// Create a Container component with dynamic width and height
// that displays its children with evenly spaced and centered
// in the middle of the container.
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

// Create a container with background color 235	215	0
// with padding of 2rem
const YellowBox = styled.div`
  background-color: #eedc00;
  padding: 2rem;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
`;

const YellowButton = styled.button`
  background-color: #eedc00;
  padding: 1rem;
  color: black;
  font-weight: bold;
  border-radius: 100px;
  font-size: 1rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  cursor: pointer;
  :hover {
    background-color: #006699;
    color: #eedc00;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
`;
