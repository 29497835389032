import {Route, Routes} from 'react-router-dom';
import './App.css';
import Checkout from './pages/Checkout';
import Customise from './pages/Customise';
import {EGapComponents} from './pages/e-gap/constants';
import Landing from './pages/Landing';
import TestBench from './pages/TestBench';

function App() {
  return (
    <Routes>
      <Route element={<Landing />} path="/landing" />
      <Route element={<Checkout />} path="/checkout" />
      <Route element={<Customise />} path="/customise" />
      <Route element={<TestBench />} path="/test-bench" />
      <Route element={<TestBench />} path="/" />
      <Route path="/e-gap">
        <Route element={<EGapComponents.Register />} path="register" />
        <Route element={<EGapComponents.Checkout />} path="checkout" />
        <Route element={<EGapComponents.GetOtp />} path="get-otp" />

        <Route element={<EGapComponents.Home />} path="" />
        <Route element={<EGapComponents.Home />} path="*" />
      </Route>
    </Routes>
  );
}

export default App;
