import {useState} from 'react';
import styled from 'styled-components';
import {ODSAdapter} from '../../adapter/ODSAdapter';
import {Spinner} from '../Spinner';
import {Button} from './Button';
import {MainPage} from './MainPage';

enum ActionLoadingState {
  None,
  Loading,
  Error,
  Success,
}

export const GetOtp = () => {
  const [otpRequestState, setOtpRequestState] = useState(ActionLoadingState.None);
  const [otpLink, setOtpLink] = useState<string | null>(null);

  const requestOTP = async () => {
    setOtpRequestState(ActionLoadingState.Loading);

    try {
      const {
        uuid: responseRequestId,
        start: startDate,
        end: endDate,
        managementToken,
        merchantId,
      } = JSON.parse(localStorage.getItem('app_data') ?? '{}');

      const timestamp = startDate;

      // 8h from start date is the maximum time for OTP request
      const timestampEnd = timestamp + 1000 * 60 * 60 * 8;
      const _otpLink = await ODSAdapter.getODSOTP(managementToken, merchantId, responseRequestId, [
        timestamp,
        endDate < timestampEnd ? endDate : timestampEnd,
      ]);

      setOtpLink(_otpLink);
      setOtpRequestState(ActionLoadingState.Success);

      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOtpRequestState(ActionLoadingState.None);
    } catch (error) {
      setOtpRequestState(ActionLoadingState.Error);
      console.error(JSON.stringify(error));

      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOtpRequestState(ActionLoadingState.None);
    }
  };

  return (
    <MainPage>
      <Wrapper>
        <Button onClick={() => requestOTP()}>
          {otpRequestState === ActionLoadingState.None && 'Request OTP'}
          {otpRequestState === ActionLoadingState.Loading && <Spinner />}
          {otpRequestState === ActionLoadingState.Success && 'OTP requested'}
          {otpRequestState === ActionLoadingState.Error && 'Error'}
        </Button>
        {otpLink && (
          <Button as="a" href={otpLink} rel="noreferer" style={{marginTop: '1rem'}} target="_blank">
            Open service app
          </Button>
        )}
      </Wrapper>
    </MainPage>
  );
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
