import {css} from 'styled-components';

export const safariSelector = (...args: [any]) => css`
  @supports (-webkit-touch-callout: none) or (selector(:nth-child(1 of x))) {
    ${css(...args)};
  }
`;

export const maxWidthHeight = css`
  height: 100vh;
  width: 100vw;

  ${safariSelector`
    height: 100vdh;
    width: 100vdw;
  `}
`;
