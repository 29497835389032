import {PropsWithChildren} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import EGapLogo from '../../assets/egap-logo.svg';

export const MainPage = ({children}: PropsWithChildren<{}>) => {
  const [params] = useSearchParams();

  const clientId = params.get('clientId') ?? '';
  const merchantId = params.get('merchantId') ?? '';
  const managementToken = params.get('managementToken') ?? '';

  return (
    <MainWrapper>
      <Link to={`/e-gap?clientId=${clientId}&managementToken=${managementToken}&merchantId=${merchantId}`}>
        <Nav>
          <MenuWrapper>
            <MenuItem />
            <MenuItem />
            <MenuItem />
          </MenuWrapper>
          <LogoWrapper src={EGapLogo} />
        </Nav>
      </Link>
      <ContentWrapper>{children}</ContentWrapper>
    </MainWrapper>
  );
};

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fefefe;
  height: 100dvh;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
`;

// #region navbar
const Nav = styled.div`
  height: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 2.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fefffe;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #aaaaaa;
`;

const LogoWrapper = styled.img`
  margin-left: auto;
  margin-right: auto;
  max-height: 1.75rem;
`;

const MenuWrapper = styled.div`
  height: 1rem;
  width: 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MenuItem = styled.div`
  height: 0.125rem;
  background-color: #12b206;
  border-radius: 0.5rem;
`;
// #endregion
