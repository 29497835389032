import {useMemo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';
import {ODSAdapter} from '../../adapter/ODSAdapter';
import {CustomInput, DateTimePicker as DefaultDateTimePicker} from '../components/DateTimePicker';
import {Spinner} from '../Spinner';
import {Button} from './Button';
import {MainPage} from './MainPage';

enum ActionLoadingState {
  None,
  Loading,
  Error,
  Success,
}

const passcode = 'passcode';

export const Checkout = () => {
  const [params] = useSearchParams();

  const [orderState, setOrderState] = useState(ActionLoadingState.None);
  const [endDate, setEndDate] = useState(0);
  const [startDate, setStartDate] = useState(0);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const clientId = params.get('clientId') ?? '';
  const merchantId = params.get('merchantId') ?? '';
  const managementToken = params.get('managementToken') ?? '';

  const placeOrder = async () => {
    setOrderState(ActionLoadingState.Loading);

    const uuid = uuidv4();
    const licensePlate = vehicleInfo?.publicIdentifier;

    try {
      const responseRequestId = await ODSAdapter.initiateODS(
        managementToken,
        merchantId,
        licensePlate,
        uuid,
        [startDate, endDate],
        passcode,
      );

      // save sent data in a object
      const data = {
        uuid: responseRequestId,
        licensePlate,
        start: startDate,
        end: endDate,
        time: new Date().toLocaleString(),
        managementToken,
        merchantId,
        passcode,
        clientId,
      };

      // save current date in readable format in local storage
      localStorage.setItem('app_data', JSON.stringify(data));

      // eslint-disable-next-line no-console
      console.log(responseRequestId, startDate, endDate);
      setOrderState(ActionLoadingState.Success);

      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOrderState(ActionLoadingState.None);
    } catch (error) {
      setOrderState(ActionLoadingState.Error);
      console.error(JSON.stringify(error));

      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOrderState(ActionLoadingState.None);
    }
  };

  const vehicleInfo = useMemo(() => {
    try {
      // what?
      const vehicleData = JSON.parse(JSON.parse(params.get('vehiclesData') ?? '{}'));
      const vehicles = vehicleData.data;

      if (vehicles && vehicles.length > 0) {
        return vehicles[0];
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }, [params]);

  const vehicleDescription = useMemo(
    () =>
      vehicleInfo?.make && vehicleInfo?.modelName
        ? `${vehicleInfo.make} ${vehicleInfo.modelName}`.replace('+', ' ')
        : '',
    [vehicleInfo],
  );

  const endDateDescription = () => {
    if (endDate) {
      const date = new Date(endDate);

      return `Within ${date.getHours()}:${date.getMinutes()}`;
    }

    return 'Select';
  };

  return (
    <MainPage>
      <Wrapper>
        <Title>Check your data before confirming the payment</Title>
        <Grid>
          <GridItem style={{cursor: 'pointer'}} onClick={() => setShowDatePicker(!showDatePicker)}>
            <Label>Slot</Label>
            <GridItemValue>{endDateDescription()}</GridItemValue>
          </GridItem>
          <GridItem>
            <Label>Duration/KWH</Label>
            <GridItemValue>30min/5KWh</GridItemValue>
          </GridItem>
          <GridItem>
            <Label>Price</Label>
            <GridItemValue>5€</GridItemValue>
          </GridItem>
        </Grid>
        {showDatePicker && (
          <>
            <Label style={{marginTop: '1rem'}}>Start</Label>
            <DateTimePicker value={startDate} onChange={(n) => setStartDate(n)} />
            <Label style={{marginTop: '1rem'}}>End</Label>
            <DateTimePicker minDate={startDate} value={endDate} onChange={(n) => setEndDate(n)} />
          </>
        )}
        <Separator />
        <Disclaimer>The service is completed when is either reached the max duration or the max kWh.</Disclaimer>
        <Separator />
        <Label>Car</Label>
        <Description>{vehicleDescription}</Description>
        <Separator />
        <Label>Payment method</Label>
        <Description>
          &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679; 4242
        </Description>
        <ConfirmButton
          disabled={
            orderState !== ActionLoadingState.None || !vehicleInfo?.publicIdentifier || startDate === 0 || endDate === 0
          }
          onClick={() => placeOrder()}
        >
          {orderState === ActionLoadingState.None && 'Confirm'}
          {orderState === ActionLoadingState.Loading && <Spinner />}
          {orderState === ActionLoadingState.Success && 'Order created'}
          {orderState === ActionLoadingState.Error && 'Error'}
        </ConfirmButton>
      </Wrapper>
    </MainPage>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Title = styled.h4`
  color: #888888;
  font-weight: normal;
  text-align: center;
`;

const Disclaimer = styled(Title)`
  margin: 0;
  color: #59af36;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const GridItem = styled.div`
  text-align: center;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: 0.75rem;
  color: #888888;
  margin-bottom: 0.75rem;
`;

const GridItemValue = styled.div`
  color: #59af36;
  font-size: 0.85rem;
`;

const Separator = styled.div`
  background-color: #e6e6e6;
  height: 1px;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Description = styled(Title)`
  text-align: left;
  font-weight: bold;
  margin: 0;
`;

const ConfirmButton = styled(Button)`
  margin-top: 3rem;
`;

const DateTimePicker = styled(DefaultDateTimePicker)`
  ${CustomInput} {
    height: 2rem;
    font-size: 1rem;
  }
`;
