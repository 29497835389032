import styled from 'styled-components';

export const Button = styled.button<{inverted?: boolean}>`
  background-color: ${({inverted}) => (inverted ? '#ffffff' : '#59af36')};
  border: 1px solid ${({inverted}) => (inverted ? '#59af36' : 'transparent')};
  border-radius: 0.75rem;
  box-sizing: border-box;
  color: ${({inverted}) => (inverted ? '#59af36' : '#ffffff')};
  cursor: pointer;
  flex: 0 0 auto;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0.75rem 1.2rem;
  text-align: center;
  text-decoration: none #6b7280 solid;
  text-decoration-thickness: auto;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  min-width: 12rem;
  min-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({inverted}) => (inverted ? '#59af36' : '#64c33c')};
    color: #ffffff;
  }

  &:focus {
    box-shadow: none;
    outline: 2px solid white;
    outline-offset: 2px;
  }

  &:disabled {
    opacity: 0.65;
  }
`;
