import {ODSAdapter} from '../adapter/ODSAdapter';
import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import React from 'react';
import PosteItalianeLogo from '../assets/Poste_italiane_blu_logo.svg';
import {useSearchParams} from 'react-router-dom';
import {maxWidthHeight} from '../utility/constants';

const passcode = 'passcode';

enum OrderState {
  None,
  Loading,
  Error,
  Success,
}

const Customise = () => {
  const [params] = useSearchParams();
  const serviceToken = params.get('serviceToken') ?? '';
  const managementToken = params.get('managementToken') ?? '';
  const [orderState, setOrderState] = React.useState(OrderState.None);

  const [publicIdentifier, setPublicIdentifier] = React.useState('');

  const twentySecondsAgo = new Date(Date.now() - 20000);

  const placeOrder = async () => {
    const uuid = uuidv4();
    setOrderState(OrderState.Loading);
    // get unix timestamp in milliseconds for 20s ago
    const timestampStart = twentySecondsAgo.getTime();
    // get unix timestamp in milliseconds for 5h from now
    const timestampEnd = Date.now() + 1000 * 60 * 60 * 0.04;
    try {
      const successfullRequestId = await ODSAdapter.initiateODS(
        managementToken,
        serviceToken,
        publicIdentifier,
        uuid,
        [timestampStart, timestampEnd],
        passcode,
      );
      // save sent data in a object
      const data = {
        uuid: successfullRequestId,
        publicIdentifier,
        start: timestampStart,
        end: timestampEnd,
        time: new Date().toLocaleString(),
        passcode,
      };

      // save current date in readable format in local storage
      localStorage.setItem(uuid, JSON.stringify(data));
      // eslint-disable-next-line no-console
      console.log(successfullRequestId, timestampStart, timestampEnd);
      setOrderState(OrderState.Success);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOrderState(OrderState.None);
    } catch (error) {
      setOrderState(OrderState.Error);
      console.error(JSON.stringify(error));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setOrderState(OrderState.None);
    }
  };

  return (
    <Background>
      <Header>
        <Logo src={PosteItalianeLogo} />
      </Header>
      <Container>
        <MilkmanCard>
          <MilkmanCardHeader>
            <MilkmanCardHeaderText>Via Mantova 53, 00198, Roma</MilkmanCardHeaderText>
          </MilkmanCardHeader>
          <MilkmanCardBody>
            <MilkmanCardBodyTitle>Personalizza la tua consegna</MilkmanCardBodyTitle>
            <MilkmanCardBodyMerchantImage src="https://cdn.milkmantechnologies.com/common/merchants/nespresso.png" />
            <MilkmanCardBodyCalendarContainer>
              <MilkmanCardBodyCalendar>8 Luglio</MilkmanCardBodyCalendar>
              <MilkmanCardBodyCalendarTextContainer>
                <MilkmanCardBodyCalendarText>
                  Alle {twentySecondsAgo.toLocaleTimeString().split(':').slice(0, 2).join(':')}
                </MilkmanCardBodyCalendarText>
              </MilkmanCardBodyCalendarTextContainer>
            </MilkmanCardBodyCalendarContainer>
            <FakeModifyButtonText>Modifica</FakeModifyButtonText>
            <LicensePlateInputContainer>
              <LicensePlateInputTitle>Inserisci note</LicensePlateInputTitle>
              <LicensePlateInput value={publicIdentifier} onChange={(e) => setPublicIdentifier(e.target.value)} />
            </LicensePlateInputContainer>
            <CreateOrderButtonContainer>
              <CreateOrderButton onClick={() => placeOrder()}>
                {orderState === OrderState.Loading && <Spinner />}
                {orderState === OrderState.Success && 'Modifiche effettuate con successo'}
                {orderState === OrderState.Error && 'Errore nella modifica'}
                {orderState === OrderState.None && 'Conferma modifiche'}
              </CreateOrderButton>
            </CreateOrderButtonContainer>
          </MilkmanCardBody>
        </MilkmanCard>
      </Container>
    </Background>
  );
};

// Spinning indicator component
const Spinner = styled.div`
  width: 12px;
  height: 12px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Header = styled.div`
  height: 62px;
  background-color: rgb(238, 220, 0);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: 47px;
`;

const Background = styled.div`
  ${maxWidthHeight}
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgb(27, 104, 229) 25.29%, rgb(10, 67, 159) 100%);
`;

const MilkmanCard = styled.div`
  width: 25rem;
  min-width: 125px;
  min-height: 70px;
  border-top-left-radius: 1.1111rem;
  border-top-right-radius: 1.1111rem;
  border-bottom-right-radius: 1.1111rem;
  border-bottom-left-radius: 1.1111rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 40px 20px;
  cursor: initial;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const MilkmanCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.3333rem;
  border-top-left-radius: 0.8889rem;
  border-top-right-radius: 0.8889rem;
  background-color: rgb(171, 203, 255);
  cursor: pointer;
`;

const MilkmanCardHeaderText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  font-size: 0.8889rem;
  white-space: nowrap;
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  color: rgb(0, 52, 138);
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: calc(100% - 3.3333rem);
  margin-left: 0px;
  margin-right: 0px;
`;

const MilkmanCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: rgb(255, 255, 255);
  color: rgb(130, 130, 130);
`;
const MilkmanCardBodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 2.2222rem;
  padding-bottom: 0rem;
  padding-left: 2.2222rem;
  padding-right: 2.2222rem;
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1.3333rem;
  text-align: center;
  color: rgb(79, 79, 79);
`;

const MilkmanCardBodyMerchantImage = styled.img`
  display: flex;
  flex-direction: column;
  object-fit: contain;
  align-items: center;
  padding-top: 1.1111rem;
  padding-bottom: 0rem;
  padding-left: 2.2222rem;
  padding-right: 2.2222rem;
`;

const MilkmanCardBodyCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.1111rem;
  padding-bottom: 0rem;
  padding-left: 2.2222rem;
  padding-right: 2.2222rem;
`;

const MilkmanCardBodyCalendar = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  font-weight: 600;
  background-color: rgb(30, 101, 217);
  color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  width: 100%;
  height: 5.5556rem;
  margin-top: 0.8889rem;
  margin-bottom: 0.8889rem;
  font-size: 1.6667rem;
`;

const MilkmanCardBodyCalendarTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.1111rem;
  padding-bottom: 0.5556rem;
  padding-left: 2.2222rem;
  padding-right: 2.2222rem;
`;

const MilkmanCardBodyCalendarText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1.3333rem;
  color: rgb(130, 130, 130);
`;

const FakeModifyButtonText = styled.div`
  text-decoration: underline;
  font-size: 1.3333rem;
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  color: black;
  text-align: center;
  width: 100%;
  padding-top: 1.1111rem;
  cursor: pointer;
  :hover {
    color: rgb(0, 52, 138);
  }
`;

const CreateOrderButtonContainer = styled.div`
  width: 100%;
  padding-top: 1.1111rem;
`;

const LicensePlateInputContainer = styled.div`
  width: 100%;
  padding-top: 1.1111rem;
  padding-bottom: 0rem;
  padding-left: 2.2222rem;
  padding-right: 2.2222rem;
  box-sizing: border-box;
`;

const LicensePlateInputTitle = styled.div`
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.25rem;
  color: black;
`;

const LicensePlateInput = styled.textarea`
  width: 100%;
  height: 4rem;
  font-size: 1rem;
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  font-weight: 600;
  color: rgb(130, 130, 130);
  border: 1px solid rgb(130, 130, 130);
  padding-left: 0.25rem;
  border-radius: 0.5rem;
  resize: none;
`;

const CreateOrderButton = styled.div`
  padding-top: 0.5556rem;
  padding-bottom: 0.5556rem;
  background-color: rgb(0, 52, 138);
  border-bottom-left-radius: 1.1111rem;
  border-bottom-right-radius: 1.1111rem;
  justify-content: center;
  align-items: center;
  display: flex;
  display: flex;
  width: 100%;
  height: 3.3333rem;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: hidden;
  color: white;
  font-family: 'Custom SemiBold', 'Open Sans SemiBold', Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
`;

export default Customise;
