export const ODSAdapter = {
  baseUrl: 'https://ods.2hire.io/api/v1',
  initiateODS: (
    managementToken: string,
    serviceToken: string,
    publicIdentifier: string,
    requestID: string,
    timeframe: [number, number],
    passcode: string,
  ): Promise<RequestID> => {
    return fetch(`${ODSAdapter.baseUrl}/ods/initiate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${managementToken}`,
        'x-service-token': serviceToken,
      },
      body: JSON.stringify({
        publicIdentifier: publicIdentifier,
        requestId: requestID,
        timeframe: timeframe,
        passcode: passcode,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          return response.data.requestId;
        }
        throw new Error(response.error);
      });
  },
  getODSOTP: (
    managementToken: string,
    serviceToken: string,
    requestID: string,
    timeframe: [number, number],
  ): Promise<string> => {
    return fetch(`${ODSAdapter.baseUrl}/ods/${requestID}/otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${managementToken}`,
        'x-service-token': serviceToken,
      },
      body: JSON.stringify({
        timeframe: timeframe,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success === true) {
          return response.data.otpURL;
        }
        throw new Error(response.error);
      });
  },
};

export type RequestID = string;
