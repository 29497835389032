import {Register} from './Register';
import {Home} from './Home';
import {Checkout} from './Checkout';
import {GetOtp} from './GetOtp';

const BaseUrl = 'ods.2hire.io';

const CallBackUrl = (clientID: string, merchantId: string, managementToken: string) =>
  // eslint-disable-next-line max-len
  `${window.location.protocol}//${window.location.host}/e-gap/checkout?managementToken=${managementToken}&merchantId=${merchantId}&clientId=${clientID}`;

// eslint-disable-next-line max-len
export const OptInLink = (clientID: string, merchantId: string, managementToken: string) =>
  `https://${BaseUrl}/webapp?client_id=${clientID}&merchant_id=${merchantId}&callback_uri=${encodeURIComponent(
    CallBackUrl(clientID, merchantId, managementToken),
  )}`;

export const EGapComponents = {
  Register,
  Home,
  Checkout,
  GetOtp,
};

export const Constants = {
  OptInLink,
};
