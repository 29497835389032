import React from 'react';
import styled from 'styled-components';
import logo from '../assets/Nespresso-logo.svg';
import {maxWidthHeight} from '../utility/constants';

enum OrderState {
  None,
  Loading,
  Error,
  Success,
}

const Checkout = () => {
  const [orderState, setOrderState] = React.useState(OrderState.None);
  const placeOrder = async () => {
    setOrderState(OrderState.Loading);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setOrderState(OrderState.Success);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setOrderState(OrderState.None);
  };
  return (
    <Background>
      <Header>
        <Logo src={logo} />
      </Header>
      <Container>
        <LeftContainer>
          <CheckoutSection>
            <SectionHeader>
              <SectionHeaderText>Shipping Details</SectionHeaderText>
              <SectionHeaderEditButton>Edit</SectionHeaderEditButton>
            </SectionHeader>
            <SectionBody>
              <SectionBodyText>Christian Gerini</SectionBodyText>
              <SectionBodyText>2hire</SectionBodyText>
              <SectionBodyText>c.gerini@2hire.io</SectionBodyText>
              <SectionBodyText>Via Mantova 52, Roma, Lazio 00198, Italy</SectionBodyText>
              <SectionBodyText>3357687389</SectionBodyText>
            </SectionBody>
          </CheckoutSection>
          <CheckoutSection>
            <SectionHeader>
              <SectionHeaderText>Delivery method</SectionHeaderText>
              <SectionHeaderEditButton>Edit</SectionHeaderEditButton>
            </SectionHeader>
            <SectionBody>
              <SectionBodyText>Free delivery</SectionBodyText>
            </SectionBody>
          </CheckoutSection>
          <CheckoutSection>
            <SectionHeader>
              <SectionHeaderText>Payment</SectionHeaderText>
              <SectionHeaderEditButton>Edit</SectionHeaderEditButton>
            </SectionHeader>
            <SectionBody>
              <SectionBodyText>
                &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679; &#9679;&#9679;&#9679;&#9679; 4242
              </SectionBodyText>
              <SectionBodyText>Christian Gerini</SectionBodyText>
              <SectionBodyText>2hire</SectionBodyText>
              <SectionBodyText>c.gerini@2hire.io</SectionBodyText>
              <SectionBodyText>Via Mantova 52, Roma, Lazio 00198, Italy</SectionBodyText>
              <SectionBodyText>3357687389</SectionBodyText>
            </SectionBody>
          </CheckoutSection>
          <CheckoutSection>
            <SectionHeader>
              <SectionHeaderText>Review and place order</SectionHeaderText>
            </SectionHeader>
            <SectionBody>
              <SectionBodyTextBlack>
                Review the order details above, and place your order when you&apos;re ready.
              </SectionBodyTextBlack>
              <TermsAndConditionsCheckbox />
            </SectionBody>
          </CheckoutSection>
          <BlackButton onClick={() => placeOrder()}>
            {orderState === OrderState.Loading && <Spinner />}
            {orderState === OrderState.Success && 'Order placed!'}
            {orderState === OrderState.Error && 'Error placing order'}
            {orderState === OrderState.None && 'Place order'}
          </BlackButton>
        </LeftContainer>
        <RightContainer>
          <CheckoutRecapCard>
            <CheckoutRecapCardTitle>ORDER DETAILS</CheckoutRecapCardTitle>
            <CheckoutRecapCardGrid>
              <CheckoutReacapGridTitle>ORIGINAL CAPSULES</CheckoutReacapGridTitle>
              <CheckoutRecapCell />
              <CheckoutRecapCell />
              <CheckoutRecapCell color={'gray'}>Kahawa ya Congo</CheckoutRecapCell>
              <CheckoutRecapCell color={'gray'} textAlign={'right'}>
                x 340
              </CheckoutRecapCell>
              <CheckoutRecapCell color={'red'} textAlign={'right'}>
                € 204.00
              </CheckoutRecapCell>
            </CheckoutRecapCardGrid>
            <CheckoutRecapCardGrid>
              <CheckoutRecapCell color={'gray'}>SUBTOTAL</CheckoutRecapCell>
              <CheckoutRecapCell />
              <CheckoutRecapCell color={'red'} textAlign={'right'}>
                € 204.00
              </CheckoutRecapCell>
              <CheckoutRecapCell color={'gray'}>VAT{'(incl.)'}22%</CheckoutRecapCell>
              <CheckoutRecapCell />
              <CheckoutRecapCell color={'red'} textAlign={'right'}>
                € 36.79
              </CheckoutRecapCell>
            </CheckoutRecapCardGrid>
            <CheckoutRecapCardGrid>
              <CheckoutRecapCellBiggerText>TOTAL</CheckoutRecapCellBiggerText>
              <CheckoutRecapCell />
              <CheckoutRecapCell color={'red'} textAlign={'right'}>
                € 204.00
              </CheckoutRecapCell>
            </CheckoutRecapCardGrid>
            <CheckoutRecapCardModifyButton>MODIFY</CheckoutRecapCardModifyButton>
          </CheckoutRecapCard>
        </RightContainer>
      </Container>
    </Background>
  );
};

export default Checkout;

const Background = styled.div`
  ${maxWidthHeight}
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  height: 50px;
  background-color: #1b1b1b;
  padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Logo = styled.img`
  height: 100%;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  // media query for mobile
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    height: auto;
  }
`;

const LeftContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;

const CheckoutSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 1rem;
  :first-child {
    padding-top: 0;
  }
  :last-of-type {
    margin-bottom: 1rem;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SectionHeaderText = styled.div`
  font-size: 2rem;
`;

const SectionHeaderEditButton = styled.div`
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
`;

const SectionBody = styled.div`
  text-align: left;
  color: gray;
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid gray;
`;

const SectionBodyText = styled.div`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

const SectionBodyTextBlack = styled(SectionBodyText)`
  color: black;
`;

const TermsAndConditionsCheckbox = () => {
  return (
    <CheckboxContainer>
      <CheckboxLabel>
        <CheckboxInput type="checkbox" />
        <CheckboxText>
          I agree to the <TermsAndConditionsLink>Terms and Conditions</TermsAndConditionsLink>
        </CheckboxText>
      </CheckboxLabel>
    </CheckboxContainer>
  );
};

// Spinning indicator component
const Spinner = styled.div`
  width: 12px;
  height: 12px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const CheckboxLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CheckboxInput = styled.input`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border: 1px solid black;
  border-radius: 0.5rem;
  background-color: white;
  cursor: pointer;
`;

const CheckboxText = styled.div`
  font-size: 1rem;
  color: gray;
  cursor: pointer;
`;

const TermsAndConditionsLink = styled.div`
  color: black;
  text-decoration: underline;
  cursor: pointer;
`;

// Black button with rounded corners and white text
const BlackButton = styled.button`
  background-color: black;
  color: white;
  font-weight: bold;
  border: none;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #1b1b1b;
  }
`;

const CheckoutRecapCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
  box-sizing: border-box;
`;

const CheckoutRecapCardTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 1rem;
  text-align: center;
`;

const CheckoutRecapCardGrid = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  border-bottom: 1px solid gray;
  :last-of-type {
    border-bottom: none;
  }
`;

const CheckoutRecapCell = styled.div<{color?: string; textAlign?: string}>`
  color: ${(props) => props.color};
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: ${(props) => props.textAlign};
`;

const CheckoutRecapCellBiggerText = styled(CheckoutRecapCell)`
  font-size: 1.5rem;
  font-weight: bold;
`;

const CheckoutReacapGridTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const CheckoutRecapCardModifyButton = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  background-color: white;
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.8rem;
  margin-top: 1rem;
  cursor: pointer;
  :hover {
    background-color: #1b1b1b;
    color: white;
  }
`;
